import _core from "./lib/core.js";
import _c from "highlight.js/lib/languages/1c";
import _abnf from "highlight.js/lib/languages/abnf";
import _accesslog from "highlight.js/lib/languages/accesslog";
import _actionscript from "highlight.js/lib/languages/actionscript";
import _ada from "highlight.js/lib/languages/ada";
import _angelscript from "highlight.js/lib/languages/angelscript";
import _apache from "highlight.js/lib/languages/apache";
import _applescript from "highlight.js/lib/languages/applescript";
import _arcade from "highlight.js/lib/languages/arcade";
import _cpp from "highlight.js/lib/languages/cpp";
import _arduino from "highlight.js/lib/languages/arduino";
import _armasm from "highlight.js/lib/languages/armasm";
import _xml from "highlight.js/lib/languages/xml";
import _asciidoc from "highlight.js/lib/languages/asciidoc";
import _aspectj from "highlight.js/lib/languages/aspectj";
import _autohotkey from "highlight.js/lib/languages/autohotkey";
import _autoit from "highlight.js/lib/languages/autoit";
import _avrasm from "highlight.js/lib/languages/avrasm";
import _awk from "highlight.js/lib/languages/awk";
import _axapta from "highlight.js/lib/languages/axapta";
import _bash from "highlight.js/lib/languages/bash";
import _basic from "highlight.js/lib/languages/basic";
import _bnf from "highlight.js/lib/languages/bnf";
import _brainfuck from "highlight.js/lib/languages/brainfuck";
import _cal from "highlight.js/lib/languages/cal";
import _capnproto from "highlight.js/lib/languages/capnproto";
import _ceylon from "highlight.js/lib/languages/ceylon";
import _clean from "highlight.js/lib/languages/clean";
import _clojure from "highlight.js/lib/languages/clojure";
import _clojureRepl from "highlight.js/lib/languages/clojure-repl";
import _cmake from "highlight.js/lib/languages/cmake";
import _coffeescript from "highlight.js/lib/languages/coffeescript";
import _coq from "highlight.js/lib/languages/coq";
import _cos from "highlight.js/lib/languages/cos";
import _crmsh from "highlight.js/lib/languages/crmsh";
import _crystal from "highlight.js/lib/languages/crystal";
import _cs from "highlight.js/lib/languages/cs";
import _csp from "highlight.js/lib/languages/csp";
import _css from "highlight.js/lib/languages/css";
import _d from "highlight.js/lib/languages/d";
import _markdown from "highlight.js/lib/languages/markdown";
import _dart from "highlight.js/lib/languages/dart";
import _delphi from "highlight.js/lib/languages/delphi";
import _diff from "highlight.js/lib/languages/diff";
import _django from "highlight.js/lib/languages/django";
import _dns from "highlight.js/lib/languages/dns";
import _dockerfile from "highlight.js/lib/languages/dockerfile";
import _dos from "highlight.js/lib/languages/dos";
import _dsconfig from "highlight.js/lib/languages/dsconfig";
import _dts from "highlight.js/lib/languages/dts";
import _dust from "highlight.js/lib/languages/dust";
import _ebnf from "highlight.js/lib/languages/ebnf";
import _elixir from "highlight.js/lib/languages/elixir";
import _elm from "highlight.js/lib/languages/elm";
import _ruby from "highlight.js/lib/languages/ruby";
import _erb from "highlight.js/lib/languages/erb";
import _erlangRepl from "highlight.js/lib/languages/erlang-repl";
import _erlang from "highlight.js/lib/languages/erlang";
import _excel from "highlight.js/lib/languages/excel";
import _fix from "highlight.js/lib/languages/fix";
import _flix from "highlight.js/lib/languages/flix";
import _fortran from "highlight.js/lib/languages/fortran";
import _fsharp from "highlight.js/lib/languages/fsharp";
import _gams from "highlight.js/lib/languages/gams";
import _gauss from "highlight.js/lib/languages/gauss";
import _gcode from "highlight.js/lib/languages/gcode";
import _gherkin from "highlight.js/lib/languages/gherkin";
import _glsl from "highlight.js/lib/languages/glsl";
import _gml from "highlight.js/lib/languages/gml";
import _go from "highlight.js/lib/languages/go";
import _golo from "highlight.js/lib/languages/golo";
import _gradle from "highlight.js/lib/languages/gradle";
import _groovy from "highlight.js/lib/languages/groovy";
import _haml from "highlight.js/lib/languages/haml";
import _handlebars from "highlight.js/lib/languages/handlebars";
import _haskell from "highlight.js/lib/languages/haskell";
import _haxe from "highlight.js/lib/languages/haxe";
import _hsp from "highlight.js/lib/languages/hsp";
import _htmlbars from "highlight.js/lib/languages/htmlbars";
import _http from "highlight.js/lib/languages/http";
import _hy from "highlight.js/lib/languages/hy";
import _inform from "highlight.js/lib/languages/inform7";
import _ini from "highlight.js/lib/languages/ini";
import _irpf from "highlight.js/lib/languages/irpf90";
import _isbl from "highlight.js/lib/languages/isbl";
import _java from "highlight.js/lib/languages/java";
import _javascript from "highlight.js/lib/languages/javascript";
import _jbossCli from "highlight.js/lib/languages/jboss-cli";
import _json from "highlight.js/lib/languages/json";
import _julia from "highlight.js/lib/languages/julia";
import _juliaRepl from "highlight.js/lib/languages/julia-repl";
import _kotlin from "highlight.js/lib/languages/kotlin";
import _lasso from "highlight.js/lib/languages/lasso";
import _ldif from "highlight.js/lib/languages/ldif";
import _leaf from "highlight.js/lib/languages/leaf";
import _less from "highlight.js/lib/languages/less";
import _lisp from "highlight.js/lib/languages/lisp";
import _livecodeserver from "highlight.js/lib/languages/livecodeserver";
import _livescript from "highlight.js/lib/languages/livescript";
import _llvm from "highlight.js/lib/languages/llvm";
import _lsl from "highlight.js/lib/languages/lsl";
import _lua from "highlight.js/lib/languages/lua";
import _makefile from "highlight.js/lib/languages/makefile";
import _mathematica from "highlight.js/lib/languages/mathematica";
import _matlab from "highlight.js/lib/languages/matlab";
import _maxima from "highlight.js/lib/languages/maxima";
import _mel from "highlight.js/lib/languages/mel";
import _mercury from "highlight.js/lib/languages/mercury";
import _mipsasm from "highlight.js/lib/languages/mipsasm";
import _mizar from "highlight.js/lib/languages/mizar";
import _perl from "highlight.js/lib/languages/perl";
import _mojolicious from "highlight.js/lib/languages/mojolicious";
import _monkey from "highlight.js/lib/languages/monkey";
import _moonscript from "highlight.js/lib/languages/moonscript";
import _n1ql from "highlight.js/lib/languages/n1ql";
import _nginx from "highlight.js/lib/languages/nginx";
import _nimrod from "highlight.js/lib/languages/nimrod";
import _nix from "highlight.js/lib/languages/nix";
import _nsis from "highlight.js/lib/languages/nsis";
import _objectivec from "highlight.js/lib/languages/objectivec";
import _ocaml from "highlight.js/lib/languages/ocaml";
import _openscad from "highlight.js/lib/languages/openscad";
import _oxygene from "highlight.js/lib/languages/oxygene";
import _parser from "highlight.js/lib/languages/parser3";
import _pf from "highlight.js/lib/languages/pf";
import _pgsql from "highlight.js/lib/languages/pgsql";
import _php from "highlight.js/lib/languages/php";
import _plaintext from "highlight.js/lib/languages/plaintext";
import _pony from "highlight.js/lib/languages/pony";
import _powershell from "highlight.js/lib/languages/powershell";
import _processing from "highlight.js/lib/languages/processing";
import _profile from "highlight.js/lib/languages/profile";
import _prolog from "highlight.js/lib/languages/prolog";
import _properties from "highlight.js/lib/languages/properties";
import _protobuf from "highlight.js/lib/languages/protobuf";
import _puppet from "highlight.js/lib/languages/puppet";
import _purebasic from "highlight.js/lib/languages/purebasic";
import _python from "highlight.js/lib/languages/python";
import _q from "highlight.js/lib/languages/q";
import _qml from "highlight.js/lib/languages/qml";
import _r from "highlight.js/lib/languages/r";
import _reasonml from "highlight.js/lib/languages/reasonml";
import _rib from "highlight.js/lib/languages/rib";
import _roboconf from "highlight.js/lib/languages/roboconf";
import _routeros from "highlight.js/lib/languages/routeros";
import _rsl from "highlight.js/lib/languages/rsl";
import _ruleslanguage from "highlight.js/lib/languages/ruleslanguage";
import _rust from "highlight.js/lib/languages/rust";
import _sas from "highlight.js/lib/languages/sas";
import _scala from "highlight.js/lib/languages/scala";
import _scheme from "highlight.js/lib/languages/scheme";
import _scilab from "highlight.js/lib/languages/scilab";
import _scss from "highlight.js/lib/languages/scss";
import _shell from "highlight.js/lib/languages/shell";
import _smali from "highlight.js/lib/languages/smali";
import _smalltalk from "highlight.js/lib/languages/smalltalk";
import _sml from "highlight.js/lib/languages/sml";
import _sqf from "highlight.js/lib/languages/sqf";
import _sql from "highlight.js/lib/languages/sql";
import _stan from "highlight.js/lib/languages/stan";
import _stata from "highlight.js/lib/languages/stata";
import _step from "highlight.js/lib/languages/step21";
import _stylus from "highlight.js/lib/languages/stylus";
import _subunit from "highlight.js/lib/languages/subunit";
import _swift from "highlight.js/lib/languages/swift";
import _taggerscript from "highlight.js/lib/languages/taggerscript";
import _yaml from "highlight.js/lib/languages/yaml";
import _tap from "highlight.js/lib/languages/tap";
import _tcl from "highlight.js/lib/languages/tcl";
import _tex from "highlight.js/lib/languages/tex";
import _thrift from "highlight.js/lib/languages/thrift";
import _tp from "highlight.js/lib/languages/tp";
import _twig from "highlight.js/lib/languages/twig";
import _typescript from "highlight.js/lib/languages/typescript";
import _vala from "highlight.js/lib/languages/vala";
import _vbnet from "highlight.js/lib/languages/vbnet";
import _vbscript from "highlight.js/lib/languages/vbscript";
import _vbscriptHtml from "highlight.js/lib/languages/vbscript-html";
import _verilog from "highlight.js/lib/languages/verilog";
import _vhdl from "highlight.js/lib/languages/vhdl";
import _vim from "highlight.js/lib/languages/vim";
import _x86asm from "highlight.js/lib/languages/x86asm";
import _xl from "highlight.js/lib/languages/xl";
import _xquery from "highlight.js/lib/languages/xquery";
import _zephir from "highlight.js/lib/languages/zephir";
var exports = {};
var low = _core;
exports = low;
low.registerLanguage("1c", _c);
low.registerLanguage("abnf", _abnf);
low.registerLanguage("accesslog", _accesslog);
low.registerLanguage("actionscript", _actionscript);
low.registerLanguage("ada", _ada);
low.registerLanguage("angelscript", _angelscript);
low.registerLanguage("apache", _apache);
low.registerLanguage("applescript", _applescript);
low.registerLanguage("arcade", _arcade);
low.registerLanguage("cpp", _cpp);
low.registerLanguage("arduino", _arduino);
low.registerLanguage("armasm", _armasm);
low.registerLanguage("xml", _xml);
low.registerLanguage("asciidoc", _asciidoc);
low.registerLanguage("aspectj", _aspectj);
low.registerLanguage("autohotkey", _autohotkey);
low.registerLanguage("autoit", _autoit);
low.registerLanguage("avrasm", _avrasm);
low.registerLanguage("awk", _awk);
low.registerLanguage("axapta", _axapta);
low.registerLanguage("bash", _bash);
low.registerLanguage("basic", _basic);
low.registerLanguage("bnf", _bnf);
low.registerLanguage("brainfuck", _brainfuck);
low.registerLanguage("cal", _cal);
low.registerLanguage("capnproto", _capnproto);
low.registerLanguage("ceylon", _ceylon);
low.registerLanguage("clean", _clean);
low.registerLanguage("clojure", _clojure);
low.registerLanguage("clojure-repl", _clojureRepl);
low.registerLanguage("cmake", _cmake);
low.registerLanguage("coffeescript", _coffeescript);
low.registerLanguage("coq", _coq);
low.registerLanguage("cos", _cos);
low.registerLanguage("crmsh", _crmsh);
low.registerLanguage("crystal", _crystal);
low.registerLanguage("cs", _cs);
low.registerLanguage("csp", _csp);
low.registerLanguage("css", _css);
low.registerLanguage("d", _d);
low.registerLanguage("markdown", _markdown);
low.registerLanguage("dart", _dart);
low.registerLanguage("delphi", _delphi);
low.registerLanguage("diff", _diff);
low.registerLanguage("django", _django);
low.registerLanguage("dns", _dns);
low.registerLanguage("dockerfile", _dockerfile);
low.registerLanguage("dos", _dos);
low.registerLanguage("dsconfig", _dsconfig);
low.registerLanguage("dts", _dts);
low.registerLanguage("dust", _dust);
low.registerLanguage("ebnf", _ebnf);
low.registerLanguage("elixir", _elixir);
low.registerLanguage("elm", _elm);
low.registerLanguage("ruby", _ruby);
low.registerLanguage("erb", _erb);
low.registerLanguage("erlang-repl", _erlangRepl);
low.registerLanguage("erlang", _erlang);
low.registerLanguage("excel", _excel);
low.registerLanguage("fix", _fix);
low.registerLanguage("flix", _flix);
low.registerLanguage("fortran", _fortran);
low.registerLanguage("fsharp", _fsharp);
low.registerLanguage("gams", _gams);
low.registerLanguage("gauss", _gauss);
low.registerLanguage("gcode", _gcode);
low.registerLanguage("gherkin", _gherkin);
low.registerLanguage("glsl", _glsl);
low.registerLanguage("gml", _gml);
low.registerLanguage("go", _go);
low.registerLanguage("golo", _golo);
low.registerLanguage("gradle", _gradle);
low.registerLanguage("groovy", _groovy);
low.registerLanguage("haml", _haml);
low.registerLanguage("handlebars", _handlebars);
low.registerLanguage("haskell", _haskell);
low.registerLanguage("haxe", _haxe);
low.registerLanguage("hsp", _hsp);
low.registerLanguage("htmlbars", _htmlbars);
low.registerLanguage("http", _http);
low.registerLanguage("hy", _hy);
low.registerLanguage("inform7", _inform);
low.registerLanguage("ini", _ini);
low.registerLanguage("irpf90", _irpf);
low.registerLanguage("isbl", _isbl);
low.registerLanguage("java", _java);
low.registerLanguage("javascript", _javascript);
low.registerLanguage("jboss-cli", _jbossCli);
low.registerLanguage("json", _json);
low.registerLanguage("julia", _julia);
low.registerLanguage("julia-repl", _juliaRepl);
low.registerLanguage("kotlin", _kotlin);
low.registerLanguage("lasso", _lasso);
low.registerLanguage("ldif", _ldif);
low.registerLanguage("leaf", _leaf);
low.registerLanguage("less", _less);
low.registerLanguage("lisp", _lisp);
low.registerLanguage("livecodeserver", _livecodeserver);
low.registerLanguage("livescript", _livescript);
low.registerLanguage("llvm", _llvm);
low.registerLanguage("lsl", _lsl);
low.registerLanguage("lua", _lua);
low.registerLanguage("makefile", _makefile);
low.registerLanguage("mathematica", _mathematica);
low.registerLanguage("matlab", _matlab);
low.registerLanguage("maxima", _maxima);
low.registerLanguage("mel", _mel);
low.registerLanguage("mercury", _mercury);
low.registerLanguage("mipsasm", _mipsasm);
low.registerLanguage("mizar", _mizar);
low.registerLanguage("perl", _perl);
low.registerLanguage("mojolicious", _mojolicious);
low.registerLanguage("monkey", _monkey);
low.registerLanguage("moonscript", _moonscript);
low.registerLanguage("n1ql", _n1ql);
low.registerLanguage("nginx", _nginx);
low.registerLanguage("nimrod", _nimrod);
low.registerLanguage("nix", _nix);
low.registerLanguage("nsis", _nsis);
low.registerLanguage("objectivec", _objectivec);
low.registerLanguage("ocaml", _ocaml);
low.registerLanguage("openscad", _openscad);
low.registerLanguage("oxygene", _oxygene);
low.registerLanguage("parser3", _parser);
low.registerLanguage("pf", _pf);
low.registerLanguage("pgsql", _pgsql);
low.registerLanguage("php", _php);
low.registerLanguage("plaintext", _plaintext);
low.registerLanguage("pony", _pony);
low.registerLanguage("powershell", _powershell);
low.registerLanguage("processing", _processing);
low.registerLanguage("profile", _profile);
low.registerLanguage("prolog", _prolog);
low.registerLanguage("properties", _properties);
low.registerLanguage("protobuf", _protobuf);
low.registerLanguage("puppet", _puppet);
low.registerLanguage("purebasic", _purebasic);
low.registerLanguage("python", _python);
low.registerLanguage("q", _q);
low.registerLanguage("qml", _qml);
low.registerLanguage("r", _r);
low.registerLanguage("reasonml", _reasonml);
low.registerLanguage("rib", _rib);
low.registerLanguage("roboconf", _roboconf);
low.registerLanguage("routeros", _routeros);
low.registerLanguage("rsl", _rsl);
low.registerLanguage("ruleslanguage", _ruleslanguage);
low.registerLanguage("rust", _rust);
low.registerLanguage("sas", _sas);
low.registerLanguage("scala", _scala);
low.registerLanguage("scheme", _scheme);
low.registerLanguage("scilab", _scilab);
low.registerLanguage("scss", _scss);
low.registerLanguage("shell", _shell);
low.registerLanguage("smali", _smali);
low.registerLanguage("smalltalk", _smalltalk);
low.registerLanguage("sml", _sml);
low.registerLanguage("sqf", _sqf);
low.registerLanguage("sql", _sql);
low.registerLanguage("stan", _stan);
low.registerLanguage("stata", _stata);
low.registerLanguage("step21", _step);
low.registerLanguage("stylus", _stylus);
low.registerLanguage("subunit", _subunit);
low.registerLanguage("swift", _swift);
low.registerLanguage("taggerscript", _taggerscript);
low.registerLanguage("yaml", _yaml);
low.registerLanguage("tap", _tap);
low.registerLanguage("tcl", _tcl);
low.registerLanguage("tex", _tex);
low.registerLanguage("thrift", _thrift);
low.registerLanguage("tp", _tp);
low.registerLanguage("twig", _twig);
low.registerLanguage("typescript", _typescript);
low.registerLanguage("vala", _vala);
low.registerLanguage("vbnet", _vbnet);
low.registerLanguage("vbscript", _vbscript);
low.registerLanguage("vbscript-html", _vbscriptHtml);
low.registerLanguage("verilog", _verilog);
low.registerLanguage("vhdl", _vhdl);
low.registerLanguage("vim", _vim);
low.registerLanguage("x86asm", _x86asm);
low.registerLanguage("xl", _xl);
low.registerLanguage("xquery", _xquery);
low.registerLanguage("zephir", _zephir);
export default exports;